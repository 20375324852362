import React from "react"
import "./page.scss"
import Footer from "./footer"
import Header from "./header"

const Page = ({ children, context }) => {
  return (
    <>
      <Header />
      <main style={{margin: "10px 4vw"}}>{children}</main>
      <Footer />
    </>
  )
}

export default Page
