import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./header.scss"

const Header = () => {
  return (
  <div>
    <div className="header-container header-separator">
      <div className="left-offset" />
      <div className="left-column">
        <span className="header-font" style={{margin: "5px", fontSize: "20px", whiteSpace: "nowrap"}}>THE HONEYVERSE</span>
      </div>
      <div style={{flexGrow: "2"}} />
      <div className="right-column">
        <span><a href="/">🇺🇸 English</a></span>
        <span><a href="/index_cn">🇨🇳 中文</a></span>
      </div>
    </div>
    <div className="header-container">
      <div className="left-offset" />
      <div className="left-column">
        <a href="/#">
          <StaticImage style={{width: "120px"}} src="../../static/img/HoneyverseNoText.png" alt="The Honeyverse Logo" />
        </a>
      </div>
      <div style={{display: "flex", flexGrow: "2", justifyContent: "center", marginTop: "36px"}}>
      </div>
      <div style={{width: "30%"}} />
    </div>
    <div className="header-banner-container">
      <div className="header-banner">
        <div className="header-font header-heading">
          RAW HONEY
        </div>
        <div className="header-font header-subheading">
          Zero chemicals, zero additives, 100% pure
        </div>
      </div>
    </div>
  </div>
  )
}

export default Header
